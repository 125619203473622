<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回酒店详情页</el-button
      >
    </div>

    <div class="activity_create_bg">
      <h4>订单列表</h4>
      <p v-if="this.$route.query.type == 'orderTotal'">预定总量</p>
      <p
        v-if="
          this.$route.query.type == 'dailyOrderCount' ||
          this.$route.query.type == 3
        "
      >
        日期：{{ this.$route.query.roomDate }}
      </p>
    </div>

    <div class="activity_create_bg">
      <el-table :data="tableData" border>
        <el-table-column label="商品主图" align="center" width="200">
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="scope.row.roomDTO.imageList[0]"
              :preview-src-list="scope.row.roomDTO.imageList"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="roomDTO.goodsName"
          label="商品名称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.nightNum"
          label="预定时长"
          width="230"
          align="center"
        >
          <template slot-scope="{ row }">
            <p>
              {{ row.bizOrderExt.checkInTime }}——{{
                row.bizOrderExt.checkOutTime
              }}
            </p>
            <p>{{ row.bizOrderExt.nightNum }} 晚</p>
          </template>
        </el-table-column>
        <el-table-column prop="roomDTO.price" label="单价" align="center">
        </el-table-column>
        <el-table-column
          prop="roomDTO.roomCapacity"
          label="人数"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <p>成人{{ row.bizOrderExt.auditsNum }}</p>
            <p>儿童{{ row.bizOrderExt.childrenNum }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="bizOrderExt.houseNum"
          label="房间数"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="dailyStoke"
          label="实收款"
          align="center"
          width="180"
        >
          <template slot-scope="{ row }">
            <p v-if="row.payOrder.payType == 1">${{ row.payOrder.amount }}</p>
            <p v-else-if="row.payOrder.payType == 10">
              {{ row.payOrder.p95Amount }}<br />
              CPLE积分抵扣：-${{ row.payOrder.p05Amount }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="bizOrder.payOrderId"
          label="订单号"
          align="center"
          width="140"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrder.createTime"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bizOrder.orderStatus"
          label="订单状态"
          width="140"
          align="center"
        >
          <template slot-scope="{ row }">
            <div>
              {{ row.bizOrder.orderStatus }}
            </div>
            <el-button size="mini" type="text" @click="handleOrderDetail(row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import { format_time_date, format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
  },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "酒店发布管理",
        },
        {
          name: "酒店详情",
        },
        {
          name: "订单列表",
        },
      ], // 面包屑数据

      tableData: [],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldHotelGetOrderList,
      method: "POST",
      params: JSON.stringify({
        hotelId: this.$route.query.hotelId,
        roomId:
          this.$route.query.type !== "orderTotal"
            ? this.$route.query.roomId
            : null,
        roomDate:
          this.$route.query.type !== "orderTotal"
            ? this.$route.query.roomDate
            : null,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          res.data.data.list.map((item) => {
            item.bizOrderExt.checkInTime = format_time_date_noTime(
              item.bizOrderExt.checkInTime
            );
            item.bizOrderExt.checkOutTime = format_time_date_noTime(
              item.bizOrderExt.checkOutTime
            );
            item.bizOrder.createTime = format_time_date(
              item.bizOrder.createTime
            );

            if (item.bizOrder.orderStatus == 1) {
              item.bizOrder.orderStatus = "未支付";
            } else if (item.bizOrder.orderStatus == 2) {
              item.bizOrder.orderStatus = "支付审核中";
            } else if (item.bizOrder.orderStatus == 3) {
              item.bizOrder.orderStatus = "支付审核通过，已支付";
            } else if (item.bizOrder.orderStatus == 4) {
              item.bizOrder.orderStatus = "支付审核拒绝";
            } else if (item.bizOrder.orderStatus == 5) {
              item.bizOrder.orderStatus = "用户取消";
            } else if (item.bizOrder.orderStatus == 6) {
              item.bizOrder.orderStatus = "平台取消";
            } else if (item.bizOrder.orderStatus == 7) {
              item.bizOrder.orderStatus = "已确认";
            }
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    handleOrderDetail(row) {
      this.$router.push("/worldHotelOrderDetail");
      localStorage.setItem("hotelRowDetailData", JSON.stringify(row));
    },

    handleBack() {
      this.$router.push({
        path: "/worldHotelDetail",
        query: {
          hotelId: this.$route.query.hotelId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";

.activity_create_bg {
  p {
    margin: 0;
  }
}
</style>
